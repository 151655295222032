const actionTypes = Object.freeze({

    // TARGET_PRODUCT_CATEGORY
    TARGET_PRODUCT_CATEGORY_START: 'TARGET_PRODUCT_CATEGORY_START',
    TARGET_PRODUCT_CATEGORY_FAIDED: 'TARGET_PRODUCT_CATEGORY_FAIDED',
    TARGET_PRODUCT_CATEGORY_SUCCESS: 'TARGET_PRODUCT_CATEGORY_SUCCESS',

    GET_LIST_TARGET_PRODUCT_CATEGORY_SUCCESS: 'GET_LIST_TARGET_PRODUCT_CATEGORY_SUCCESS',
    GET_TARGET_PRODUCT_CATEGORY_SUCCESS: 'GET_TARGET_PRODUCT_CATEGORY_SUCCESS',
    CREATE_TARGET_PRODUCT_CATEGORY_SUCCESS: 'CREATE_TARGET_PRODUCT_CATEGORY_SUCCESS',
    DELETE_LIST_TARGET_PRODUCT_CATEGORY_SUCCESS: 'DELETE_LIST_TARGET_PRODUCT_CATEGORY_SUCCESS',
    EDIT_TARGET_PRODUCT_CATEGORY_SUCCESS: 'EDIT_TARGET_PRODUCT_CATEGORY_SUCCESS',
    EDIT_LIST_TARGET_PRODUCT_CATEGORY_SUCCESS: 'EDIT_LIST_TARGET_PRODUCT_CATEGORY_SUCCESS',
    ON_CHANGE_TARGET_PRODUCT_CATEGORY: 'ON_CHANGE_TARGET_PRODUCT_CATEGORY',
    SET_DATA_TARGET_PRODUCT_CATEGORY: 'SET_DATA_TARGET_PRODUCT_CATEGORY',

    // TARGET_SHOP
    TARGET_SHOP_START: 'TARGET_SHOP_START',
    TARGET_SHOP_FAIDED: 'TARGET_SHOP_FAIDED',
    TARGET_SHOP_SUCCESS: 'TARGET_SHOP_SUCCESS',

    GET_LIST_TARGET_SHOP_SUCCESS: 'GET_LIST_TARGET_SHOP_SUCCESS',
    GET_TARGET_SHOP_SUCCESS: 'GET_TARGET_SHOP_SUCCESS',
    CREATE_TARGET_SHOP_SUCCESS: 'CREATE_TARGET_SHOP_SUCCESS',
    DELETE_LIST_TARGET_SHOP_SUCCESS: 'DELETE_LIST_TARGET_SHOP_SUCCESS',
    EDIT_TARGET_SHOP_SUCCESS: 'EDIT_TARGET_SHOP_SUCCESS',
    EDIT_LIST_TARGET_SHOP_SUCCESS: 'EDIT_LIST_TARGET_SHOP_SUCCESS',
    ON_CHANGE_TARGET_SHOP: 'ON_CHANGE_TARGET_SHOP',
    SET_DATA_TARGET_SHOP: 'SET_DATA_TARGET_SHOP',

    // TARGET_STAFF
    TARGET_STAFF_START: 'TARGET_STAFF_START',
    TARGET_STAFF_FAIDED: 'TARGET_STAFF_FAIDED',
    TARGET_STAFF_SUCCESS: 'TARGET_STAFF_SUCCESS',

    GET_LIST_TARGET_STAFF_SUCCESS: 'GET_LIST_TARGET_STAFF_SUCCESS',
    GET_TARGET_STAFF_SUCCESS: 'GET_TARGET_STAFF_SUCCESS',
    CREATE_TARGET_STAFF_SUCCESS: 'CREATE_TARGET_STAFF_SUCCESS',
    DELETE_LIST_TARGET_STAFF_SUCCESS: 'DELETE_LIST_TARGET_STAFF_SUCCESS',
    EDIT_TARGET_STAFF_SUCCESS: 'EDIT_TARGET_STAFF_SUCCESS',
    EDIT_LIST_TARGET_STAFF_SUCCESS: 'EDIT_LIST_TARGET_STAFF_SUCCESS',
    ON_CHANGE_TARGET_STAFF: 'ON_CHANGE_TARGET_STAFF',
    SET_DATA_TARGET_STAFF: 'SET_DATA_TARGET_STAFF',

    // REPORT TARGET
    REPORT_TARGET_START: 'REPORT_TARGET_START',
    REPORT_TARGET_FAIDED: 'REPORT_TARGET_FAIDED',
    REPORT_TARGET_SUCCESS: 'REPORT_TARGET_SUCCESS',

    GET_LIST_REPORT_TARGET_SHOP_SUCCESS: 'GET_LIST_REPORT_TARGET_SHOP_SUCCESS',
    GET_ALL_REPORT_TARGET_SHOP_SUCCESS: 'GET_ALL_REPORT_TARGET_SHOP_SUCCESS',
    GET_REPORT_TARGET_SHOP_SUCCESS: 'GET_REPORT_TARGET_SHOP_SUCCESS',

    GET_LIST_REPORT_TARGET_STAFF_SUCCESS: 'GET_LIST_REPORT_TARGET_STAFF_SUCCESS',
    GET_ALL_REPORT_TARGET_STAFF_SUCCESS: 'GET_ALL_REPORT_TARGET_STAFF_SUCCESS',
    GET_REPORT_TARGET_STAFF_SUCCESS: 'GET_REPORT_TARGET_STAFF_SUCCESS',

    SET_DATA_REPORT_TARGET: 'SET_DATA_REPORT_TARGET',

    SET_DATA_FILTER_REPORT_TARGET_SHOP: 'SET_DATA_FILTER_REPORT_TARGET_SHOP',
    SET_TYPE_ACTIVE_REPORT_TARGET_SHOP: 'SET_TYPE_ACTIVE_REPORT_TARGET_SHOP',

    SET_DATA_FILTER_REPORT_TARGET_STAFF: 'SET_DATA_FILTER_REPORT_TARGET_STAFF',
    SET_TYPE_ACTIVE_REPORT_TARGET_STAFF: 'SET_TYPE_ACTIVE_REPORT_TARGET_STAFF',
})

export default actionTypes;