const actionTypes = Object.freeze({
    // TASK
    TASK_START: 'TASK_START',
    TASK_FAIDED: 'TASK_FAIDED',
    TASK_SUCCESS: 'TASK_SUCCESS',

    GET_LIST_TASK_SUCCESS: 'GET_LIST_TASK_SUCCESS',
    GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
    CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
    DELETE_LIST_TASK_SUCCESS: 'DELETE_LIST_TASK_SUCCESS',
    EDIT_TASK_SUCCESS: 'EDIT_TASK_SUCCESS',
    EDIT_LIST_TASK_SUCCESS: 'EDIT_LIST_TASK_SUCCESS',
    ON_CHANGE_TASK: 'ON_CHANGE_TASK',
    SET_DATA_TASK: 'SET_DATA_TASK',

    // USER
    USER_START: 'USER_START',
    USER_FAIDED: 'USER_FAIDED',
    USER_SUCCESS: 'USER_SUCCESS',

    GET_LIST_USER_SUCCESS: 'GET_LIST_USER_SUCCESS',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    DELETE_LIST_USER_SUCCESS: 'DELETE_LIST_USER_SUCCESS',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_LIST_USER_SUCCESS: 'EDIT_LIST_USER_SUCCESS',
    ON_CHANGE_USER: 'ON_CHANGE_USER',
    SET_DATA_USER: 'SET_DATA_USER',

    GET_LIST_USER_PERMISSION_SUCCESS: 'GET_LIST_USER_PERMISSION_SUCCESS',
    SET_IS_SUPERUSER: 'SET_IS_SUPERUSER',

    // GROUP
    GROUP_START: 'GROUP_START',
    GROUP_FAIDED: 'GROUP_FAIDED',
    GROUP_SUCCESS: 'GROUP_SUCCESS',

    GET_LIST_GROUP_SUCCESS: 'GET_LIST_GROUP_SUCCESS',
    GET_GROUP_SUCCESS: 'GET_GROUP_SUCCESS',
    CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
    DELETE_LIST_GROUP_SUCCESS: 'DELETE_LIST_GROUP_SUCCESS',
    EDIT_GROUP_SUCCESS: 'EDIT_GROUP_SUCCESS',
    EDIT_LIST_GROUP_SUCCESS: 'EDIT_LIST_GROUP_SUCCESS',
    ON_CHANGE_GROUP: 'ON_CHANGE_GROUP',
    SET_DATA_GROUP: 'SET_DATA_GROUP',

    // PERMISSION
    PERMISSION_START: 'PERMISSION_START',
    PERMISSION_FAIDED: 'PERMISSION_FAIDED',
    PERMISSION_SUCCESS: 'PERMISSION_SUCCESS',
    GET_LIST_PERMISSION_SUCCESS: 'GET_LIST_PERMISSION_SUCCESS',

    // LOGIN
    SET_LOGIN: 'SET_LOGIN',

    // DARK MODE
    SET_DARK_MODE: 'SET_DARK_MODE',
})

export default actionTypes;