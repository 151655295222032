const actionTypes = Object.freeze({

    // BRAND
    BRAND_START: 'BRAND_START',
    BRAND_FAIDED: 'BRAND_FAIDED',
    BRAND_SUCCESS: 'BRAND_SUCCESS',

    GET_LIST_BRAND_SUCCESS: 'GET_LIST_BRAND_SUCCESS',
    GET_BRAND_SUCCESS: 'GET_BRAND_SUCCESS',
    CREATE_BRAND_SUCCESS: 'CREATE_BRAND_SUCCESS',
    DELETE_LIST_BRAND_SUCCESS: 'DELETE_LIST_BRAND_SUCCESS',
    EDIT_BRAND_SUCCESS: 'EDIT_BRAND_SUCCESS',
    EDIT_LIST_BRAND_SUCCESS: 'EDIT_LIST_BRAND_SUCCESS',
    ON_CHANGE_BRAND: 'ON_CHANGE_BRAND',
    SET_DATA_BRAND: 'SET_DATA_BRAND',

    // TAG
    TAG_START: 'TAG_START',
    TAG_FAIDED: 'TAG_FAIDED',
    TAG_SUCCESS: 'TAG_SUCCESS',

    GET_LIST_TAG_SUCCESS: 'GET_LIST_TAG_SUCCESS',
    GET_TAG_SUCCESS: 'GET_TAG_SUCCESS',
    CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
    DELETE_LIST_TAG_SUCCESS: 'DELETE_LIST_TAG_SUCCESS',
    EDIT_TAG_SUCCESS: 'EDIT_TAG_SUCCESS',
    EDIT_LIST_TAG_SUCCESS: 'EDIT_LIST_TAG_SUCCESS',
    ON_CHANGE_TAG: 'ON_CHANGE_TAG',
    SET_DATA_TAG: 'SET_DATA_TAG',

    // CATEGORY
    CATEGORY_START: 'CATEGORY_START',
    CATEGORY_FAIDED: 'CATEGORY_FAIDED',
    CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',

    GET_LIST_CATEGORY_SUCCESS: 'GET_LIST_CATEGORY_SUCCESS',
    GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
    CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
    DELETE_LIST_CATEGORY_SUCCESS: 'DELETE_LIST_CATEGORY_SUCCESS',
    EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
    EDIT_LIST_CATEGORY_SUCCESS: 'EDIT_LIST_CATEGORY_SUCCESS',
    ON_CHANGE_CATEGORY: 'ON_CHANGE_CATEGORY',
    SET_DATA_CATEGORY: 'SET_DATA_CATEGORY',

    // CATEGORY_POST
    CATEGORY_POST_START: 'CATEGORY_POST_START',
    CATEGORY_POST_FAIDED: 'CATEGORY_POST_FAIDED',
    CATEGORY_POST_SUCCESS: 'CATEGORY_POST_SUCCESS',

    GET_LIST_CATEGORY_POST_SUCCESS: 'GET_LIST_CATEGORY_POST_SUCCESS',
    GET_CATEGORY_POST_SUCCESS: 'GET_CATEGORY_POST_SUCCESS',
    CREATE_CATEGORY_POST_SUCCESS: 'CREATE_CATEGORY_POST_SUCCESS',
    DELETE_LIST_CATEGORY_POST_SUCCESS: 'DELETE_LIST_CATEGORY_POST_SUCCESS',
    EDIT_CATEGORY_POST_SUCCESS: 'EDIT_CATEGORY_POST_SUCCESS',
    EDIT_LIST_CATEGORY_POST_SUCCESS: 'EDIT_LIST_CATEGORY_POST_SUCCESS',
    ON_CHANGE_CATEGORY_POST: 'ON_CHANGE_CATEGORY_POST',
    SET_DATA_CATEGORY_POST: 'SET_DATA_CATEGORY_POST',

    // POST
    POST_START: 'POST_START',
    POST_FAIDED: 'POST_FAIDED',
    POST_SUCCESS: 'POST_SUCCESS',

    GET_LIST_POST_SUCCESS: 'GET_LIST_POST_SUCCESS',
    GET_POST_SUCCESS: 'GET_POST_SUCCESS',
    CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
    DELETE_LIST_POST_SUCCESS: 'DELETE_LIST_POST_SUCCESS',
    EDIT_POST_SUCCESS: 'EDIT_POST_SUCCESS',
    EDIT_LIST_POST_SUCCESS: 'EDIT_LIST_POST_SUCCESS',
    ON_CHANGE_POST: 'ON_CHANGE_POST',
    SET_DATA_POST: 'SET_DATA_POST',

    // GROUP_ATTRIBUTE
    GROUP_ATTRIBUTE_START: 'GROUP_ATTRIBUTE_START',
    GROUP_ATTRIBUTE_FAIDED: 'GROUP_ATTRIBUTE_FAIDED',
    GROUP_ATTRIBUTE_SUCCESS: 'GROUP_ATTRIBUTE_SUCCESS',

    GET_LIST_GROUP_ATTRIBUTE_SUCCESS: 'GET_LIST_GROUP_ATTRIBUTE_SUCCESS',
    GET_GROUP_ATTRIBUTE_SUCCESS: 'GET_GROUP_ATTRIBUTE_SUCCESS',
    CREATE_GROUP_ATTRIBUTE_SUCCESS: 'CREATE_GROUP_ATTRIBUTE_SUCCESS',
    DELETE_LIST_GROUP_ATTRIBUTE_SUCCESS: 'DELETE_LIST_GROUP_ATTRIBUTE_SUCCESS',
    EDIT_GROUP_ATTRIBUTE_SUCCESS: 'EDIT_GROUP_ATTRIBUTE_SUCCESS',
    EDIT_LIST_GROUP_ATTRIBUTE_SUCCESS: 'EDIT_LIST_GROUP_ATTRIBUTE_SUCCESS',
    ON_CHANGE_GROUP_ATTRIBUTE: 'ON_CHANGE_GROUP_ATTRIBUTE',
    SET_DATA_GROUP_ATTRIBUTE: 'SET_DATA_GROUP_ATTRIBUTE',

    // VARIANT_ATTRIBUTE_GROUP
    VARIANT_ATTRIBUTE_GROUP_START: 'VARIANT_ATTRIBUTE_GROUP_START',
    VARIANT_ATTRIBUTE_GROUP_FAIDED: 'VARIANT_ATTRIBUTE_GROUP_FAIDED',
    VARIANT_ATTRIBUTE_GROUP_SUCCESS: 'VARIANT_ATTRIBUTE_GROUP_SUCCESS',

    GET_LIST_VARIANT_ATTRIBUTE_GROUP_SUCCESS: 'GET_LIST_VARIANT_ATTRIBUTE_GROUP_SUCCESS',
    GET_VARIANT_ATTRIBUTE_GROUP_SUCCESS: 'GET_VARIANT_ATTRIBUTE_GROUP_SUCCESS',
    CREATE_VARIANT_ATTRIBUTE_GROUP_SUCCESS: 'CREATE_VARIANT_ATTRIBUTE_GROUP_SUCCESS',
    DELETE_LIST_VARIANT_ATTRIBUTE_GROUP_SUCCESS: 'DELETE_LIST_VARIANT_ATTRIBUTE_GROUP_SUCCESS',
    EDIT_VARIANT_ATTRIBUTE_GROUP_SUCCESS: 'EDIT_VARIANT_ATTRIBUTE_GROUP_SUCCESS',
    EDIT_LIST_VARIANT_ATTRIBUTE_GROUP_SUCCESS: 'EDIT_LIST_VARIANT_ATTRIBUTE_GROUP_SUCCESS',
    ON_CHANGE_VARIANT_ATTRIBUTE_GROUP: 'ON_CHANGE_VARIANT_ATTRIBUTE_GROUP',
    SET_DATA_VARIANT_ATTRIBUTE_GROUP: 'SET_DATA_VARIANT_ATTRIBUTE_GROUP',

    // ATTRIBUTE
    ATTRIBUTE_START: 'ATTRIBUTE_START',
    ATTRIBUTE_FAIDED: 'ATTRIBUTE_FAIDED',
    ATTRIBUTE_SUCCESS: 'ATTRIBUTE_SUCCESS',

    GET_LIST_ATTRIBUTE_SUCCESS: 'GET_LIST_ATTRIBUTE_SUCCESS',
    GET_ATTRIBUTE_SUCCESS: 'GET_ATTRIBUTE_SUCCESS',
    CREATE_ATTRIBUTE_SUCCESS: 'CREATE_ATTRIBUTE_SUCCESS',
    DELETE_LIST_ATTRIBUTE_SUCCESS: 'DELETE_LIST_ATTRIBUTE_SUCCESS',
    EDIT_ATTRIBUTE_SUCCESS: 'EDIT_ATTRIBUTE_SUCCESS',
    EDIT_LIST_ATTRIBUTE_SUCCESS: 'EDIT_LIST_ATTRIBUTE_SUCCESS',
    ON_CHANGE_ATTRIBUTE: 'ON_CHANGE_ATTRIBUTE',
    SET_DATA_ATTRIBUTE: 'SET_DATA_ATTRIBUTE',

    // ATTRIBUTE_VALUE
    ATTRIBUTE_VALUE_START: 'ATTRIBUTE_VALUE_START',
    ATTRIBUTE_VALUE_FAIDED: 'ATTRIBUTE_VALUE_FAIDED',
    ATTRIBUTE_VALUE_SUCCESS: 'ATTRIBUTE_VALUE_SUCCESS',

    GET_LIST_ATTRIBUTE_VALUE_SUCCESS: 'GET_LIST_ATTRIBUTE_VALUE_SUCCESS',
    GET_ATTRIBUTE_VALUE_SUCCESS: 'GET_ATTRIBUTE_VALUE_SUCCESS',
    CREATE_ATTRIBUTE_VALUE_SUCCESS: 'CREATE_ATTRIBUTE_VALUE_SUCCESS',
    DELETE_LIST_ATTRIBUTE_VALUE_SUCCESS: 'DELETE_LIST_ATTRIBUTE_VALUE_SUCCESS',
    EDIT_ATTRIBUTE_VALUE_SUCCESS: 'EDIT_ATTRIBUTE_VALUE_SUCCESS',
    EDIT_LIST_ATTRIBUTE_VALUE_SUCCESS: 'EDIT_LIST_ATTRIBUTE_VALUE_SUCCESS',
    ON_CHANGE_ATTRIBUTE_VALUE: 'ON_CHANGE_ATTRIBUTE_VALUE',
    SET_DATA_ATTRIBUTE_VALUE: 'SET_DATA_ATTRIBUTE_VALUE',

    // LOCATION
    LOCATION_START: 'LOCATION_START',
    LOCATION_FAIDED: 'LOCATION_FAIDED',
    LOCATION_SUCCESS: 'LOCATION_SUCCESS',

    GET_LIST_LOCATION_SUCCESS: 'GET_LIST_LOCATION_SUCCESS',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
    DELETE_LIST_LOCATION_SUCCESS: 'DELETE_LIST_LOCATION_SUCCESS',
    EDIT_LOCATION_SUCCESS: 'EDIT_LOCATION_SUCCESS',
    EDIT_LIST_LOCATION_SUCCESS: 'EDIT_LIST_LOCATION_SUCCESS',
    ON_CHANGE_LOCATION: 'ON_CHANGE_LOCATION',
    SET_DATA_LOCATION: 'SET_DATA_LOCATION',

    // BANNER
    BANNER_START: 'BANNER_START',
    BANNER_FAIDED: 'BANNER_FAIDED',
    BANNER_SUCCESS: 'BANNER_SUCCESS',

    GET_LIST_BANNER_SUCCESS: 'GET_LIST_BANNER_SUCCESS',
    GET_BANNER_SUCCESS: 'GET_BANNER_SUCCESS',
    CREATE_BANNER_SUCCESS: 'CREATE_BANNER_SUCCESS',
    DELETE_LIST_BANNER_SUCCESS: 'DELETE_LIST_BANNER_SUCCESS',
    EDIT_BANNER_SUCCESS: 'EDIT_BANNER_SUCCESS',
    EDIT_LIST_BANNER_SUCCESS: 'EDIT_LIST_BANNER_SUCCESS',
    ON_CHANGE_BANNER: 'ON_CHANGE_BANNER',
    SET_DATA_BANNER: 'SET_DATA_BANNER',

    // REPAIR
    REPAIR_START: 'REPAIR_START',
    REPAIR_FAIDED: 'REPAIR_FAIDED',
    REPAIR_SUCCESS: 'REPAIR_SUCCESS',

    GET_LIST_REPAIR_SUCCESS: 'GET_LIST_REPAIR_SUCCESS',
    GET_REPAIR_SUCCESS: 'GET_REPAIR_SUCCESS',
    CREATE_REPAIR_SUCCESS: 'CREATE_REPAIR_SUCCESS',
    DELETE_LIST_REPAIR_SUCCESS: 'DELETE_LIST_REPAIR_SUCCESS',
    EDIT_REPAIR_SUCCESS: 'EDIT_REPAIR_SUCCESS',
    EDIT_LIST_REPAIR_SUCCESS: 'EDIT_LIST_REPAIR_SUCCESS',
    ON_CHANGE_REPAIR: 'ON_CHANGE_REPAIR',
    SET_DATA_REPAIR: 'SET_DATA_REPAIR',

    // WARRANTY
    WARRANTY_START: 'WARRANTY_START',
    WARRANTY_FAIDED: 'WARRANTY_FAIDED',
    WARRANTY_SUCCESS: 'WARRANTY_SUCCESS',

    GET_LIST_WARRANTY_SUCCESS: 'GET_LIST_WARRANTY_SUCCESS',
    GET_WARRANTY_SUCCESS: 'GET_WARRANTY_SUCCESS',
    CREATE_WARRANTY_SUCCESS: 'CREATE_WARRANTY_SUCCESS',
    DELETE_LIST_WARRANTY_SUCCESS: 'DELETE_LIST_WARRANTY_SUCCESS',
    EDIT_WARRANTY_SUCCESS: 'EDIT_WARRANTY_SUCCESS',
    EDIT_LIST_WARRANTY_SUCCESS: 'EDIT_LIST_WARRANTY_SUCCESS',
    ON_CHANGE_WARRANTY: 'ON_CHANGE_WARRANTY',
    SET_DATA_WARRANTY: 'SET_DATA_WARRANTY',

    // FLASH_SALE
    FLASH_SALE_START: 'FLASH_SALE_START',
    FLASH_SALE_FAIDED: 'FLASH_SALE_FAIDED',
    FLASH_SALE_SUCCESS: 'FLASH_SALE_SUCCESS',

    GET_LIST_FLASH_SALE_SUCCESS: 'GET_LIST_FLASH_SALE_SUCCESS',
    GET_FLASH_SALE_SUCCESS: 'GET_FLASH_SALE_SUCCESS',
    CREATE_FLASH_SALE_SUCCESS: 'CREATE_FLASH_SALE_SUCCESS',
    DELETE_LIST_FLASH_SALE_SUCCESS: 'DELETE_LIST_FLASH_SALE_SUCCESS',
    EDIT_FLASH_SALE_SUCCESS: 'EDIT_FLASH_SALE_SUCCESS',
    EDIT_LIST_FLASH_SALE_SUCCESS: 'EDIT_LIST_FLASH_SALE_SUCCESS',
    ON_CHANGE_FLASH_SALE: 'ON_CHANGE_FLASH_SALE',
    SET_DATA_FLASH_SALE: 'SET_DATA_FLASH_SALE',

    // PROMOTION
    PROMOTION_START: 'PROMOTION_START',
    PROMOTION_FAIDED: 'PROMOTION_FAIDED',
    PROMOTION_SUCCESS: 'PROMOTION_SUCCESS',

    GET_LIST_PROMOTION_SUCCESS: 'GET_LIST_PROMOTION_SUCCESS',
    GET_PROMOTION_SUCCESS: 'GET_PROMOTION_SUCCESS',
    CREATE_PROMOTION_SUCCESS: 'CREATE_PROMOTION_SUCCESS',
    DELETE_LIST_PROMOTION_SUCCESS: 'DELETE_LIST_PROMOTION_SUCCESS',
    EDIT_PROMOTION_SUCCESS: 'EDIT_PROMOTION_SUCCESS',
    EDIT_LIST_PROMOTION_SUCCESS: 'EDIT_LIST_PROMOTION_SUCCESS',
    ON_CHANGE_PROMOTION: 'ON_CHANGE_PROMOTION',
    SET_DATA_PROMOTION: 'SET_DATA_PROMOTION',

    // PRODUCT
    PRODUCT_START: 'PRODUCT_START',
    PRODUCT_FAIDED: 'PRODUCT_FAIDED',
    PRODUCT_SUCCESS: 'PRODUCT_SUCCESS',

    GET_LIST_PRODUCT_SUCCESS: 'GET_LIST_PRODUCT_SUCCESS',
    GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
    CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
    DELETE_LIST_PRODUCT_SUCCESS: 'DELETE_LIST_PRODUCT_SUCCESS',
    EDIT_PRODUCT_SUCCESS: 'EDIT_PRODUCT_SUCCESS',
    EDIT_LIST_PRODUCT_SUCCESS: 'EDIT_LIST_PRODUCT_SUCCESS',
    ON_CHANGE_PRODUCT: 'ON_CHANGE_PRODUCT',
    ON_CHANGE_PRODUCT_DESCRIPTION: 'ON_CHANGE_PRODUCT_DESCRIPTION',
    ON_CHANGE_PRODUCT_SHORT_DESCRIPTION: 'ON_CHANGE_PRODUCT_SHORT_DESCRIPTION',
    SET_DATA_PRODUCT: 'SET_DATA_PRODUCT',
    SET_DATA_PRODUCT_ORIGINAL: 'SET_DATA_PRODUCT_ORIGINAL',
    CLICK_EDIT_PRODUCT: 'CLICK_EDIT_PRODUCT',
    SET_IS_EDIT_PRODUCT: 'SET_IS_EDIT_PRODUCT',
    SET_DATA_FILTER_PRODUCT: 'SET_DATA_FILTER_PRODUCT',
    SET_DATA_FILTER_PRODUCT_REPAIR: 'SET_DATA_FILTER_PRODUCT_REPAIR',

    // FLASH_SALE_ITEM
    FLASH_SALE_ITEM_START: 'FLASH_SALE_ITEM_START',
    FLASH_SALE_ITEM_FAIDED: 'FLASH_SALE_ITEM_FAIDED',
    FLASH_SALE_ITEM_SUCCESS: 'FLASH_SALE_ITEM_SUCCESS',

    GET_LIST_FLASH_SALE_ITEM_SUCCESS: 'GET_LIST_FLASH_SALE_ITEM_SUCCESS',
    GET_FLASH_SALE_ITEM_SUCCESS: 'GET_FLASH_SALE_ITEM_SUCCESS',
    CREATE_FLASH_SALE_ITEM_SUCCESS: 'CREATE_FLASH_SALE_ITEM_SUCCESS',
    DELETE_LIST_FLASH_SALE_ITEM_SUCCESS: 'DELETE_LIST_FLASH_SALE_ITEM_SUCCESS',
    CREATE_LIST_FLASH_SALE_ITEM_SUCCESS: 'CREATE_LIST_FLASH_SALE_ITEM_SUCCESS',
    EDIT_FLASH_SALE_ITEM_SUCCESS: 'EDIT_FLASH_SALE_ITEM_SUCCESS',
    EDIT_LIST_FLASH_SALE_ITEM_SUCCESS: 'EDIT_LIST_FLASH_SALE_ITEM_SUCCESS',
    ON_CHANGE_FLASH_SALE_ITEM: 'ON_CHANGE_FLASH_SALE_ITEM',
    SET_DATA_FLASH_SALE_ITEM: 'SET_DATA_FLASH_SALE_ITEM',

    // PRODUCT_PAGE
    PRODUCT_PAGE_START: 'PRODUCT_PAGE_START',
    PRODUCT_PAGE_FAIDED: 'PRODUCT_PAGE_FAIDED',
    PRODUCT_PAGE_SUCCESS: 'PRODUCT_PAGE_SUCCESS',

    GET_LIST_PRODUCT_PAGE_SUCCESS: 'GET_LIST_PRODUCT_PAGE_SUCCESS',
    GET_PRODUCT_PAGE_SUCCESS: 'GET_PRODUCT_PAGE_SUCCESS',
    CREATE_PRODUCT_PAGE_SUCCESS: 'CREATE_PRODUCT_PAGE_SUCCESS',
    DELETE_LIST_PRODUCT_PAGE_SUCCESS: 'DELETE_LIST_PRODUCT_PAGE_SUCCESS',
    EDIT_PRODUCT_PAGE_SUCCESS: 'EDIT_PRODUCT_PAGE_SUCCESS',
    EDIT_LIST_PRODUCT_PAGE_SUCCESS: 'EDIT_LIST_PRODUCT_PAGE_SUCCESS',
    ON_CHANGE_PRODUCT_PAGE: 'ON_CHANGE_PRODUCT_PAGE',
    SET_DATA_PRODUCT_PAGE: 'SET_DATA_PRODUCT_PAGE',

    // MEIDA_BASE
    MEIDA_BASE_START: 'MEIDA_BASE_START',
    MEIDA_BASE_FAIDED: 'MEIDA_BASE_FAIDED',
    MEIDA_BASE_SUCCESS: 'MEIDA_BASE_SUCCESS',

    GET_LIST_MEIDA_BASE_SUCCESS: 'GET_LIST_MEIDA_BASE_SUCCESS',
    GET_MEIDA_BASE_SUCCESS: 'GET_MEIDA_BASE_SUCCESS',
    CREATE_MEIDA_BASE_SUCCESS: 'CREATE_MEIDA_BASE_SUCCESS',
    DELETE_LIST_MEIDA_BASE_SUCCESS: 'DELETE_LIST_MEIDA_BASE_SUCCESS',
    EDIT_MEIDA_BASE_SUCCESS: 'EDIT_MEIDA_BASE_SUCCESS',
    EDIT_LIST_MEIDA_BASE_SUCCESS: 'EDIT_LIST_MEIDA_BASE_SUCCESS',
    ON_CHANGE_MEIDA_BASE: 'ON_CHANGE_MEIDA_BASE',
    SET_DATA_MEIDA_BASE: 'SET_DATA_MEIDA_BASE',

    // VARIANT
    VARIANT_START: 'VARIANT_START',
    VARIANT_FAIDED: 'VARIANT_FAIDED',
    VARIANT_SUCCESS: 'VARIANT_SUCCESS',

    GET_LIST_VARIANT_SUCCESS: 'GET_LIST_VARIANT_SUCCESS',
    GET_VARIANT_SUCCESS: 'GET_VARIANT_SUCCESS',
    CREATE_VARIANT_SUCCESS: 'CREATE_VARIANT_SUCCESS',
    DELETE_LIST_VARIANT_SUCCESS: 'DELETE_LIST_VARIANT_SUCCESS',
    EDIT_VARIANT_SUCCESS: 'EDIT_VARIANT_SUCCESS',
    EDIT_LIST_VARIANT_SUCCESS: 'EDIT_LIST_VARIANT_SUCCESS',
    ON_CHANGE_VARIANT: 'ON_CHANGE_VARIANT',
    SET_DATA_VARIANT: 'SET_DATA_VARIANT',
    CLICK_EDIT_VARIANT: 'CLICK_EDIT_VARIANT',
    SET_DATA_FILTER_VARIANT: 'SET_DATA_FILTER_VARIANT',
    SET_DATA_VARIANTS: 'SET_DATA_VARIANTS',

    SET_DATA_VARIANT_ORIGINAL: 'SET_DATA_VARIANT_ORIGINAL',
    SET_IS_EDIT_VARIANT: 'SET_IS_EDIT_VARIANT',
})

export default actionTypes;