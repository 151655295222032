import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    async componentDidMount() {
    }
    render() {
        return (
            <div className='bg-white'>

            </div>
        );
    }

}
export default withRouter(index);
